<template lang="pug">
.parallax(
  :class="textSize && `parallax--${textSize}`"
  :style="style"
  alt="Parallax image"
  )
  slot
</template>

<script setup lang="ts">
const props = defineProps<{
  background: string
  textSize?: 'small' | 'medium' | 'large'
  height?: number
}>()

const style = ref({
  backgroundImage: `url(${props.background})`,
  'min-height': props.height ? `${props.height}px` : '350px'
})

</script>

<style scoped lang="scss">
.parallax {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 3rem;
  text-align: center;
  padding: double() simple();

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax--small {
  :slotted(h2) {
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.5rem;
  }
}

:slotted(h2) {
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.5rem;
  text-transform: uppercase;
  max-width: 60rem;
  margin-bottom: 0;
}

@include media(touch) {
  :slotted(h2) {
    font-size: 2.6rem;
    line-height: 3.1rem;
  }
}

@include media(portrait) {
  :slotted(h2) {
    font-size: 2.2rem;
    line-height: 2.8rem;
  }

  .parallax--small {
    :slotted(h2) {
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 2.2rem;
    }
  }
}
</style>
